<template>
  <div class="reports">
    <v-row align="center" justify="center">
      <v-dialog
        :overlay-color="'#000000'"
        :overlay-opacity="0.9"
        v-model="openModal"
        max-width="500"
      >
        <v-card width="500" class="pr-10 pl-10 pt-4 pb-4" min-height="400">
          <v-icon class="float-right mt-3 mr-n5" @click="openModal = false"
            >mdi-close</v-icon
          >
          <v-card-title>
            <h4 class="white--text ml-n10 mb-5" style="font-weight: 100">
              Create report for {{ customerName }}
            </h4>
          </v-card-title>
          <v-row class="ml-n4 mt-5 mb-5"> Choose report type:</v-row>
          <v-row class="mt-5 ml-n4">
            <v-overflow-btn
              id="startReport"
              ref="startReport"
              v-model="createReportType"
              label="Start a Report"
              :items="reportTypes"
              item-text="name"
              item-value="id"
              outlined
            ></v-overflow-btn>
          </v-row>
          <v-row class="mt-5">
            <v-btn color="primary" @click="createReport"> Create Report</v-btn>
          </v-row>
        </v-card>
      </v-dialog>
      <v-alert height="80" dark width="960px" prominent class="mb-8 mt-3">
        <v-row class="d-flex no-gutters">
          <v-col cols="10">
            <h2 class="mt-1 font-weight-light">
              Reports for {{ customerName }}
            </h2>
          </v-col>

          <v-col cols="2">
            <v-btn
              color="primary"
              class="mt-1 ml-16"
              icon
              text
              @click="digitalFootprint"
            >
              <v-icon>mdi-format-list-bulleted-type</v-icon></v-btn
            >
            <v-btn
              color="#E86051"
              class="mt-1 ml-2"
              icon
              text
              @click="openModal = true"
            >
              <v-icon>mdi-plus-circle</v-icon></v-btn
            >
          </v-col>
        </v-row>
      </v-alert>
      <div class="mt-0">
        <v-row
          ><v-col cols="3">
            <v-select
              :items="reportTypes"
              label="Report Type"
              v-model="chosenReportType"
              @change="updateChosenReportType(chosenReportType)"
              item-text="name"
              item-value="id"
              class="ml-4"
            ></v-select
          ></v-col>
          <v-col cols="3" class="mt-4">
            <date-picker
              :submitHandler="updateDateRangeStart"
              :initialStartDate="null"
              :range="false"
              :clearInput="clearStart"
              ref="datePickerStartDate"
            />
            <v-icon
              class="float-right mr-10 mt-n7"
              @click="
                updateDateRangeStart([null]);
                clearStart = true;
              "
              >mdi-close</v-icon
            >
          </v-col>
          <v-col cols="3" class="ml-n10 mt-4">
            <date-picker
              :submitHandler="updateDateRangeEnd"
              :initialStartDate="null"
              :range="false"
              :clearInput="clearEnd"
              ref="datePickerEndDate"
            /><v-icon
              class="float-right mr-10 mt-n7"
              @click="
                updateDateRangeEnd([null]);
                clearEnd = true;
              "
              >mdi-close</v-icon
            >
          </v-col>
        </v-row>

        <v-data-table
          :headers="headers"
          :items="reports"
          :items-per-page="-1"
          disable-sort
          :search="search"
          hide-default-footer
        >
          <template v-slot:item.report_type="{ item }">
            <div v-if="reportTypes">
              <div v-for="(frt, index) in reportTypes" :key="index">
                <div v-if="item.report_type == frt.id">
                  {{ frt.name }}
                </div>
              </div>
            </div>
          </template>
          <template v-slot:item.created_at="{ item }">
            <div v-if="item.created_at == null">-----</div>
            <div v-else>
              {{
                new Date(item.created_at)
                  .toString("YYYY-MM-dd")
                  .substring(0, 15)
              }}
            </div>
          </template>
          <template v-slot:item.completed_at="{ item }">
            <div v-if="item.completed_at == null">-----</div>
            <div v-else>
              {{
                new Date(item.completed_at)
                  .toString("YYYY-MM-dd")
                  .substring(0, 15)
              }}
            </div>
          </template>
          <template v-slot:item.downloadLink="{ item }">
            <span
              v-if="reportStatus.completed.includes(item.report_id)"
              style="cursor: pointer"
              @click="downloadCsv(item.report_id, item.report_type)"
              ><v-icon color="#fff" class="rowIcon">mdi-download</v-icon></span
            >
            <span v-else> ----- </span>
          </template>
          <template v-slot:item.resultLink="{ item }">
            <span
              v-if="reportStatus.completed.includes(item.report_id)"
              style="cursor: pointer"
              @click="goToReportDetails(item.report_id, item.report_type)"
            >
              <v-icon color="#fff" class="rowIcon">mdi-open-in-new</v-icon>
            </span>
            <span v-else> ----- </span>
          </template>
          <template v-slot:item.status="{ item }">
            <div v-if="reportStatus.completed.includes(item.report_id)">
              <v-icon color="success" class="rowIcon">
                mdi-checkbox-marked-circle
              </v-icon>
            </div>
            <div v-if="reportStatus.pending.includes(item.report_id)">
              <v-icon color="yellow" class="rowIcon"> mdi-minus-circle </v-icon>
            </div>
            <div v-if="reportStatus.failed.includes(item.report_id)">
              <v-icon color="error" class="rowIcon"> mdi-close-circle </v-icon>
            </div>
          </template>
        </v-data-table>
        <div style="min-height: 300px">&nbsp;</div>
        <infinite-loading
          :identifier="infiniteId"
          @infinite="infiniteHandler"
          v-if="reports.length > 19"
        >
          <div slot="spinner">
            <v-row>
              <div v-for="(row, index) in 6" :key="index">
                <v-skeleton-loader
                  class="mx-auto"
                  max-width="960px"
                  width="960px"
                  type="text"
                  style="margin-top: 1em; margin-bottom: 1em"
                  dark
                ></v-skeleton-loader>
                <v-skeleton-loader
                  class="mx-auto"
                  max-width="960px"
                  width="960px"
                  type="divider"
                  style="margin-top: 1em; margin-bottom: 1em"
                  dark
                ></v-skeleton-loader>
              </div>
            </v-row>
          </div>
          <div slot="no-more" class="body-2 mt-2">&nbsp;</div>
          <div slot="no-results" class="body-2 mt-2">&nbsp;</div>
        </infinite-loading>
        <v-fade-transition>
          <v-btn
            v-scroll="onScroll"
            v-show="fab"
            fab
            dark
            fixed
            bottom
            right
            outlined
            color="white"
            @click="toTop"
          >
            <v-icon>mdi-arrow-up-bold</v-icon>
          </v-btn>
        </v-fade-transition>

        <v-snackbar
          v-model="showClipboard"
          color="#58abe7"
          centered
          :timeout="3000"
          elevation="24"
        >
          {{ showMsg }}
        </v-snackbar>
      </div>
    </v-row>
  </div>
</template>
<script>
import store from "../store/index";
import {
  listUsers,
  listReports,
  reportTypesGet,
  createReport,
  getReportArchive,
  setPermalinkParams,
} from "../../src/clients/tracelight";
import "vue-material-design-icons/styles.css";
import InfiniteLoading from "vue-infinite-loading";
import DatePicker from "@/components/DatePicker";
import moment from "moment";
export default {
  name: "Reports",
  components: {
    InfiniteLoading,
    DatePicker,
  },
  data() {
    return {
      initialLoad: true,
      openModal: false,
      createReportType: null,
      clearStart: false,
      clearEnd: false,
      showClipboard: false,
      showMsg: null,
      search: null,
      infiniteId: 1,
      startDate: null,
      endDate: null,
      chosenReportType: null,
      page: 1,
      fab: false,
      headers: [
        {
          text: "Report Type",
          align: "left",

          value: "report_type",
        },
        { text: "Date Started", value: "created_at" },
        { text: "Download", value: "downloadLink" },

        { text: "Report Link", value: "resultLink" },
        { text: "Status", value: "status" },
      ],
    };
  },
  computed: {
    reportTypes() {
      const reportTypes = store.state.customerList.reportTypes || [];

      const filteredReportTypes = reportTypes.filter(function (value) {
        return (
          value.name !== "Monitoring" && value.name !== "Administrative Report"
        );
      });

      filteredReportTypes.unshift({
        name: "All",
        id: null,
      });
      return filteredReportTypes;
    },
    reports() {
      return store.state.customerList.reports || [];
    },
    users() {
      return store.state.customerList.users || [];
    },
    customerName() {
      return store.state.customerList.customerName;
    },
    reportStatus() {
      const pending = [];
      const failed = [];
      const completed = [];
      const total = { completed: completed, pending: pending, failed: failed };
      for (let a = 0; a < this.reports.length; a++) {
        let eCresult = this.reports[a];
        if (eCresult.error !== null) {
          failed.push(eCresult.report_id);
        } else if (eCresult.completed_at == null && eCresult.error == null) {
          pending.push(eCresult.report_id);
        } else {
          completed.push(eCresult.report_id);
        }
      }
      return total;
    },
  },
  created() {
    const chosenCustomer = this.$route.params.clientId;
    const payload = {};
    listUsers(payload, { results_per_page: 3000 })
      .then(function (result) {
        store.commit("customerList/fillUsers", result.data.data);
      })
      .catch(function (error) {
        console.error(error);
        alert(error);
      });
    const customerInfo = {
      client_id: this.$route.params.clientId,
    };
    store.dispatch("customerList/getCustomerById", customerInfo);
    reportTypesGet(payload, { results_per_page: 3000 })
      .then(function (result) {
        store.commit("customerList/fillReportTypes", result.data.data);
      })
      .catch(function (error) {
        console.error(error);
        alert(error);
      });
  },
  mounted() {
    let queryString = window.location.search;
    let urlParams = new URLSearchParams(queryString);
    this.updateUrlParams(urlParams);
    this.pollData();
  },
  methods: {
    updateChosenReportType(val) {
      this.page = 1;
      if (this.initialLoad == false) {
        this.updateReportsList();
      }
    },
    updateUrlParams(urlParams) {
      return new Promise((resolve, reject) => {
        for (const [key, value] of urlParams.entries()) {
          const val = urlParams.get(key);
          if (key == "report_type" && val !== "null") {
            this.chosenReportType = val;
            this.updateChosenReportType();
          }
          if (key == "completed_before" && val !== "null") {
            this.$refs.datePickerEndDate.dateData =
              moment(val).format("YYYY-MM-DD");
          }
          if (key == "completed_after" && val !== "null") {
            this.$refs.datePickerStartDate.dateData =
              moment(val).format("YYYY-MM-DD");
          }
        }
        resolve(true);
      });
    },
    downloadCsv(reportId, reportType) {
      const payload = {};
      const params = {
        client_id: this.$route.params.clientId,
        report_id: reportId,
        report_type: reportType,
        csvs_only: true,
      };
      getReportArchive(payload, params)
        .then((res) => {
          console.log(res);
          if (res.data.data.s3) {
            const downloadAnchor = document.createElement("a");
            downloadAnchor.href = res.data.data.s3.url;
            downloadAnchor.click();
            downloadAnchor.remove();
          } else {
            this.showClipboard = true;
            this.showMsg = `Sorry this report is not yet ready for download.`;
          }
        })
        .catch(function (error) {
          console.warn(error);
        });
    },
    pollData() {
      this.polling = setInterval(() => {
        this.page = 1;

        if (this.initialLoad == false) {
          this.updateReportsList();
        }
      }, 30000);
    },
    createReport() {
      const payload = {};
      const chosenCustomer = this.$route.params.clientId;
      const params = {
        client_id: chosenCustomer,
        report_type: this.createReportType,
      };
      console.log(params);
      createReport(payload, params)
        .then((res) => {
          console.log(res.data.data);
          if (res.data.data.errorMessage || res.data.data.error) {
            this.showClipboard = true;
            this.showMsg = `Sorry there was an error, please try again.`;
          } else {
            this.openModal = false;
            this.showClipboard = true;
            this.showMsg =
              "Success! New report created!\n\nCreated report ID #" +
              res.data.data.reportId +
              "\n\nStatus: " +
              res.data.data.status;

            this.createReportType = null;
            this.updateReportsList();
          }
        })
        .catch((error) => {
          console.error(error);
          this.showClipboard = true;
          this.showMsg = "Sorry there was an error, please try again.";
          this.createReportType = null;
        });
    },
    updateReportsList() {
      const payload = {};
      const chosenCustomer = this.$route.params.clientId;
      let params = {
        client_id: chosenCustomer,
        results_per_page: 20,
        report_type: this.chosenReportType,
        page: this.page,
        sort_order: "desc",
        sort_by: "created_at",
        completed_before: this.endDate,
        completed_after: this.startDate,
      };

      setPermalinkParams(params);

      for (const param of Object.keys(params)) {
        if (params[param] == "null" || params[param] == null) {
          delete params[param];
        }
      }
      listReports(payload, params)
        .then(function (result) {
          store.commit("customerList/fillReports", result.data.data);
        })
        .catch(function (error) {
          console.error(error);
          alert(error);
        });
    },
    updateDateRangeStart: function (dates) {
      if (dates !== [null]) {
        this.clearStart = false;
      }
      this.startDate = dates[0];
      this.page = 1;
      if (this.initialLoad == false) {
        this.updateReportsList();
      }
    },
    updateDateRangeEnd: function (dates) {
      if (dates !== [null]) {
        this.clearEnd = false;
      }
      this.endDate = dates[0];
      this.page = 1;

      this.updateReportsList();
      this.initialLoad = false;
    },
    onScroll(e) {
      if (typeof window === "undefined") return;
      const top = window.pageYOffset || e.target.scrollTop || 0;
      this.fab = top > 100;
    },
    toTop: function () {
      this.$vuetify.goTo(0);
    },
    infiniteHandler($state) {
      this.page++;
      const payload = {};
      const chosenCustomer = this.$route.params.clientId;
      listReports(payload, {
        client_id: chosenCustomer,
        results_per_page: 20,
        report_type: this.chosenReportType,
        page: this.page,
        sort_order: "desc",
        sort_by: "created_at",
        completed_before: this.endDate,
        completed_after: this.startDate,
      }).then((result) => {
        const reports = this.reports;
        const allReports = [...reports, ...result.data.data];
        store.commit("customerList/fillReports", allReports);
        if (result.data.data === null) {
          this.page = 1;
          $state.complete();
          return;
        }
        if (result.data.data.length > 0) {
          $state.loaded();
          return;
        }
        if (result.data.data.length === 0) {
          this.page = 1;
          $state.complete();
          return;
        }
      });
    },
    digitalFootprint() {
      window.location.href =
        window.location.origin +
        "/customer/" +
        this.$route.params.clientId +
        "/items";
    },
    goToReportDetails(reportId, reportType) {
      let routeData = this.$router.resolve({
        path:
          "/customer/" +
          this.$route.params.clientId +
          "/reports/" +
          reportId +
          "/" +
          reportType,
      });
      window.open(routeData.href, "_blank");
    },
  },
};
</script>
<style>
.reports .v-data-table__wrapper {
  min-width: 960px;
}
</style>
